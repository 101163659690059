<template>
  <div>
    <b-modal
      ref="select-image-modal"
      centered
      size="xl"
      :visible="isShowModalSelectImages"
      title="Media Library"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
      @hide="$emit('update:is-show-modal-select-images', false)"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <!-- Search bar -->
      <div class="mb-1">
        <b-row>
          <b-col cols="6">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="filters.q"
                placeholder="Search Media"
                class="search-media"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <v-select
              id="product-add-region"
              v-model="filterMedia"
              :dir="
                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
              "
              :options="mediaTypeOptions"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-col>
        </b-row>
      </div>
      <!-- List Media -->
      <b-row style="justify-content: center;">
        <div
          v-for="(image, index) in medias"
          :key="image.id"
          class="mb-5 mr-2"
          style="display: flex; flex-direction: column;"
        >
          <div
            :class="imageCss(image)"
          >
            <img
              v-if="image.media_type !== 'video'"
              width="60"
              height="60"
              :src="image.link"
              @click="onImageSelect(image)"
            >
            <div
              v-else
              style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;"
              @click="onImageSelect(image)"
            >
              <b-embed
                type="video"
              >
                <source
                  :src="image.link"
                  type="video/mp4"
                >

              </b-embed>
            </div>
          </div>

          <div
            class="tooltipcustom mt-1"
          >
            {{ start_and_end(image.title) }}
            <span class="tooltiptextcustom">{{ image.title }}</span>
          </div>
        </div>
      </b-row>

      <!-- Pagination -->
      <section class="mt-3">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalMedias"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BPagination, BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-advanced-cropper/dist/style.css'
// import VueSelectImage from 'vue-select-image'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import * as config from '../../utils/config'

export default {
  components: {
    BPagination,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BCol,
    BRow,
    BModal,
    BEmbed,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isShowModalSelectImages: {
      type: Boolean,
      required: true,
    },
    region: {
      type: String,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      mediaTypeOptions: [
        {
          label: 'Image',
          value: 'image',
        },
        {
          label: 'Video',
          value: 'video',
        },
      ],
    }
  },
  watch: {
    region(value) {
      if (!value) return
      this.fetchMedias()
    },
  },
  setup(props) {
    const selectedImage = ref([])
    const toast = useToast()
    const medias = ref([])
    const filters = ref({
      q: '',
      page: 1,
      perPage: 25,
      media_type: null,
    })
    const totalMedias = ref(null)
    const filterMedia = ref(null)
    const fetchMedias = () => {
      if(props.region){
      // selectedImage.value = []
      medias.value = []
      console.log(selectedImage.value)
      store
        .dispatch('banner/fetchBanners', {
          search: filters.value.q,
          limit: filters.value.perPage,
          region: props.region,
          page: filters.value.page,
          type: 'how_it_work',
          media_type: filterMedia.value,
        })
        .then(response => {
          medias.value = response.body.data
          totalMedias.value = response.body.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching medias list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
    }
    fetchMedias()
    watch([filters, filterMedia], () => {
      fetchMedias()
    }, {
      deep: true,
    })
    return {
      selectedImage,
      totalMedias,
      filters,
      config,
      medias,
      fetchMedias,
      filterMedia,
    }
  },
  methods: {
    start_and_end(str) {
      if (str) {
        if (str.length > 20) {
          return `${str.substr(0, 10)}...${str.substr(str.length - 10, str.length)}`
        }
        return str
      }
      return str
    },
    isSelected(index) {
      console.log(this.selectedImage.includes(index))
      console.log(index)

      return (this.selectedImage.find(x => x.id === index.id))
    },
    imageCss(imageIndex) {
      const classes = ['selectable']
      if (this.isSelected(imageIndex)) { // Add "active" if selected
        classes.push('active_box')
      }
      return classes
    },
    onImageSelect(imageIndex) {
      if (this.isSelected(imageIndex)) {
        // Leave only the selected image “other than”
        this.selectedImage = this.selectedImage.filter(selectedIndex => (selectedIndex !== imageIndex))
      } else {
        this.selectedImage.push(imageIndex)
      }
    },
    resetModal() {
      this.selectedImage = []
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$emit('set-images-id', this.selectedImage)
      //   this.$nextTick(() => {
      //     this.$refs['select-image-modal'].toggle('#toggle-btn')
      //   })
    },
  },
}
</script>
  <style lang="scss">
  .tooltipcustom {
    position: relative;
    display: inline-block;
  }

  .tooltipcustom .tooltiptextcustom {
    visibility: hidden;
    background-color: #DB536A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltipcustom:hover .tooltiptextcustom {
    visibility: visible;
  }
  .selectable {
  background:#DB536A ;
  float: left;
  width: 150px;
  height: 150px;
  margin: 5px;
  position: relative;
  }
  .active_box {
  width: 150px;
  height: 150px;
  padding: 5px;
  }
  .selectable img {
  width:100%;
  height:100%;
  object-fit:cover;
  }
  .selectable i {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 25px;
  color: #DB536A;
  background-color: #fff;
  border-radius: 10%;
  }
  </style>
